@import url('https://fonts.googleapis.com/css?family=Montserrat:regular,bold,italic&subset=latin,latin-ext');
@import url('https://fonts.googleapis.com/css?family=Lato:regular,bold,italic&subset=latin,latin-ext');
@import url('https://fonts.googleapis.com/css?family=Roboto:regular,bold,italic&subset=latin,latin-ext');

:root {
    --color-smooth-white: #F5F5F5;
    --color-almost-midnight-black: #1C1C1C;
    --color-soothing-dark-blue: #1C283C;
    --color-navy-blue: #0E4E7E;
    --color-cool-blue: #0283C0;
    --color-water-blue: #0299DC;
    --color-hot-orange: #FE8F13;
    --color-warm-orange: #FEA95E;
    --color-metal-grey: #5C5C5C;
}

html,
body {
    height: 100%;
}

body {
    font-family: 'Lato', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#root {
    display: flex;
    min-height: 100%;
    flex-direction: column;
}

.content {
    flex: 1 0 auto;
    padding-top: 150px;
    padding-bottom: 0;
    background: var(--color-smooth-white);
}

.header {
    background: var(--color-soothing-dark-blue);
    position: fixed;
    z-index: 1;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
}

.header-logo {
    float: left;
    padding: 0;
    display: block;
    margin: 24px 24px 24px 24px;
    width: 300px;
}

@media only screen and (max-width: 420px) {
    .header-logo {
        width: 200px;
    }

    .content {
        padding-top: 120px;
    }
}

.ant-menu, .ant-menu-sub, .ant-menu-inline {
    color: var(--color-smooth-white);
    background-color: var(--color-soothing-dark-blue) !important;
    border-bottom: 0;
    font-size: 24px;
    padding-top: 50px;
}

.ant-menu-submenu > .ant-menu {
    background-color: var(--color-soothing-dark-blue) !important;
    font-size: 20px;
}

.ant-menu-item-active {
    background-color: transparent !important;
    color: var(--color-hot-orange) !important;
    border-bottom: 2px solid var(--color-hot-orange) !important;
}

.ant-menu-submenu-title {
    color: var(--color-smooth-white) !important;
    font-size: 20px;
}

.ant-menu-submenu-title:active {
    background: transparent !important;
}

.ant-menu-inline {
    border: none;
}

.ant-menu-item:hover {
    color: var(--color-hot-orange) !important;
    border-bottom: 2px solid var(--color-hot-orange) !important;
    background: transparent !important;
}

.ant-menu-item:focus {
    color: var(--color-smooth-white) !important;
    border-bottom: 2px solid var(--color-hot-orange) !important;
    background: transparent !important;
}

.ant-menu-item:active {
    color: var(--color-warm-orange) !important;
    border-bottom: 2px solid var(--color-warm-orange) !important;
    background: transparent !important;
}

.ant-menu-item:before, .ant-menu-item-active:before,
.ant-menu-item:after, .ant-menu-item-active:after {
    background-color: transparent !important;
}

.ant-menu-item-open, .ant-menu-item-selected {
    color: var(--color-hot-orange) !important;
    border-bottom: 2px solid var(--color-hot-orange) !important;
    background: transparent !important;
}

.ant-menu-item-selected a {
    color: var(--color-hot-orange) !important;
}

.ant-menu-item a {
    color: var(--color-smooth-white) !important;
}

.ant-menu-item a:focus {
    color: var(--color-smooth-white) !important;
}

.ant-menu-item a:hover {
    color: var(--color-hot-orange) !important;
}

.ant-menu-item a:active {
    color: var(--color-warm-orange) !important;
}

.ant-menu-item-group-title {
    color: var(--color-hot-orange);
    font-size: 16px;
}

.ant-menu-submenu-title:hover{
    color: var(--color-hot-orange) !important;
}

.ant-menu-submenu-title:focus{
    color: var(--color-hot-orange) !important;
}

.ant-menu-submenu-title:active{
    color: var(--color-hot-orange) !important;

}

.ant-menu > .ant-menu-submenu-open,
.ant-menu > .ant-menu-submenu-selected {
    color: var(--color-hot-orange) !important;
}

.ant-menu-submenu-arrow {
    color: var(--color-smooth-white);
}

.ant-menu-submenu-arrow:hover {
    color: var(--color-hot-orange);
}

.ant-menu-submenu-arrow:focus {
    color: var(--color-hot-orange);
}

.ant-menu-submenu-arrow:active {
    color: var(--color-warm-orange);
}

.ant-menu-submenu-active {
    background-color: transparent !important;
    color: var(--color-hot-orange) !important;
    border-bottom: 2px solid var(--color-hot-orange) !important;
}

.ant-menu-horizontal > .ant-menu-item::after,
.ant-menu-horizontal > .ant-menu-submenu::after {
    border-bottom: none !important;
    transition: none !important;
    background: transparent !important;
}

.ant-menu-submenu.ant-menu-submenu-inline>.ant-menu-submenu-title>.ant-menu-submenu-arrow:after,
.ant-menu-submenu.ant-menu-submenu-inline>.ant-menu-submenu-title>.ant-menu-submenu-arrow:before {
    background-color: var(--color-smooth-white) !important;
}

.ant-menu-submenu-open.ant-menu-submenu-inline>.ant-menu-submenu-title>.ant-menu-submenu-arrow:after,
.ant-menu-submenu-open.ant-menu-submenu-inline>.ant-menu-submenu-title>.ant-menu-submenu-arrow:before {
    background-color: var(--color-hot-orange) !important;
}

.ant-btn-primary {
    color: var(--color-soothing-dark-blue) !important;
    border-color: var(--color-soothing-dark-blue) !important;
    background-color: transparent !important;
    font-size: 20px;
    height: 60px;
    border-radius: 15px;
    border-width: 3px;
    text-align: center;
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
    .ant-btn-primary {
        font-size: 18px;
        height: 50px;
        border-width: 2px;
    }
}

@media only screen and (max-width: 767px) {
    .ant-btn-primary {
        font-size: 16px;
        height: 40px;
        border-width: 2px;
    }
}

.ant-btn-primary:hover {
    color: var(--color-soothing-dark-blue) !important;
    border-color: var(--color-soothing-dark-blue) !important;
    background-color: var(--color-hot-orange) !important;
}

.ant-btn-primary:active {
    color: var(--color-soothing-dark-blue) !important;
    border-color: var(--color-soothing-dark-blue) !important;
    background-color: var(--color-warm-orange) !important;
}

.ant-carousel .slick-prev,
.ant-carousel .slick-next {
    display: block;
    position: absolute;
    top: 50%;
    margin-left: -20px;
    color: var(--color-smooth-white);
    font-size: 40px;
    background: transparent;
}

.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover {
    color: var(--color-hot-orange);

}

.ant-carousel .slick-prev:focus,
.ant-carousel .slick-next:focus {
    color: var(--color-smooth-white);
}

.ant-carousel .slick-prev:active,
.ant-carousel .slick-next:active {
    color: var(--color-warm-orange);
}

.ant-carousel .slick-dots {
    margin-bottom: -40px;
}

.ant-carousel2 .slick-prev,
.ant-carousel2 .slick-next {
    color: var(--color-soothing-dark-blue);
}

.ant-carousel2 .slick-prev:hover,
.ant-carousel2 .slick-next:hover {
    color: var(--color-hot-orange);

}

.ant-carousel2 .slick-prev:focus,
.ant-carousel2 .slick-next:focus {
    color: var(--color-soothing-dark-blue);
}

.ant-carousel2 .slick-prev:active,
.ant-carousel2 .slick-next:active {
    color: var(--color-warm-orange);
}

.ant-carousel2 .slick-dots {
    margin-bottom: -20px;
}

.ant-carousel2 .slick-dots li button {
    background: var(--color-soothing-dark-blue);
    opacity: 0.3;
}

.ant-carousel2 .slick-dots li.slick-active button {
    background: var(--color-soothing-dark-blue);
    opacity: 1;
}

.footer {
    background: var(--color-soothing-dark-blue);
}

.footer-logo {
    float: left;
    width: 50px;
}

@media only screen and (max-width: 576px) {
    .footer-logo {
        padding-bottom: 20px;
    }
}

.a-footer:link {
    color: var(--color-smooth-white);
    background-color: transparent;
    text-decoration: none;
    font-size: 14px;
}

.a-footer:visited {
    color: var(--color-smooth-white);
    background-color: transparent;
    text-decoration: none;
}

.a-footer:hover {
    color: var(--color-hot-orange);
    background-color: transparent;
    text-decoration: underline;
}

.a-footer:active {
    color: var(--color-warm-orange);
    background-color: transparent;
    text-decoration: underline;
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
    .a-footer:link {
        font-size: 13px;
    }
}

@media only screen and (max-width: 767px) {
    .a-footer:link {
        font-size: 12px;
    }
}

.footer-copyright {
    background: var(--color-almost-midnight-black);
    color: var(--color-smooth-white);
}

.bg-dark {
    background: var(--color-soothing-dark-blue);
}

.padded-t10{
    padding-top: 10px;
}

.padded-t25{
    padding-top: 25px;
}

.padded-t50{
    padding-top: 50px;
}

.padded-t100{
    padding-top: 100px;
}

.padded-b10{
    padding-bottom: 10px;
}

.padded-b25{
    padding-bottom: 25px;
}

.padded-b50{
    padding-bottom: 50px;
}

.padded-b100{
    padding-bottom: 100px;
}

h1 {
    font-size: 40px;
}

h2 {
    font-size: 34px;
}

h3 {
    font-size: 28px;
}

h4 {
    font-size: 24px;
}

h5 {
    font-size: 20px;
}

h6 {
    font-size: 16px;
}

p {
    white-space: pre-line;
    text-align: left;
    color: var(--color-soothing-dark-blue);
    font-size: 20px;
}

.p-small {
    font-size: 18px;
}

.ul-custom {
    list-style: none; /* Remove default bullets */
}

.ul-custom li::before {
    content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
    color: var(--color-hot-orange); /* Change the color */
    display: inline-block; /* Needed to add space between the bullet and the text */
    width: 1.5em; /* Also needed for space (tweak if needed) */
    margin-left: -1.5em; /* Also needed for space (tweak if needed) */
}

.ol-custom {
    list-style: none; /* Remove default bullets */
    counter-reset: cnt;
}

.ol-custom li {
    counter-increment: cnt;
}

.ol-custom li::before {
    content: counter(cnt)".";
    color: var(--color-hot-orange);
    display: inline-block;
    width: 1.5em;
    margin-left: -1.5em;
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
    h1 {
        font-size: 32px;
    }

    h2 {
        font-size: 28px;
    }

    h3 {
        font-size: 24px;
    }

    h4 {
        font-size: 20px;
    }

    h5 {
        font-size: 17px;
    }

    h6 {
        font-size: 14px;
    }

    p {
        white-space: pre-line;
        text-align: left;
        color: var(--color-soothing-dark-blue);
        font-size: 17px;
    }

    .p-small {
        font-size: 14px;
    }
}

@media only screen and (max-width: 767px) {
    h1 {
        font-size: 26px;
    }

    h2 {
        font-size: 23px;
    }

    h3 {
        font-size: 20px;
    }

    h4 {
        font-size: 17px;
    }

    h5 {
        font-size: 14px;
    }

    h6 {
        font-size: 13px;
    }

    p {
        white-space: pre-line;
        text-align: left;
        color: var(--color-soothing-dark-blue);
        font-size: 14px;
    }

    .p-small {
        font-size: 12px;
    }
}

.justified {
    text-align: justify;
}

.flushed-left {
    text-align:left;
    padding: 0;
    margin: 0;
    position: absolute;
}

.centered {
    text-align: center;
}

.flushed-right {
    text-align: right;
}

.bold {
    font-weight: bold;
}

.italic {
    font-style: italic;
}

.underlined {
    text-decoration: underline;
}

.light-text {
    color: var(--color-smooth-white);
}

.dark-text {
    color: var(--color-soothing-dark-blue);
}

.orange-text {
    color: var(--color-hot-orange);
}

.blue-text {
    color: var(--color-water-blue);
}

.horizontally-centered {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.btn-menu {
    color: var(--color-smooth-white) !important;
    border-color: var(--color-smooth-white) !important;
    background-color: transparent !important;
    font-size: 24px;
    height: 60px;
    border-radius: 15px;
    border-width: 3px;
    position: absolute;
    right: 20px;
    top: -25px;
}

@media only screen and (max-width: 420px) {
    .btn-menu {
        font-size: 20px;
        height: 50px;
        border-radius: 15px;
        border-width: 2px;
        top: -20px;
    }
}

.btn-menu:hover {
    color: var(--color-hot-orange) !important;
    border-color: var(--color-hot-orange) !important;
    background-color: transparent !important;
}

.btn-menu:active {
    color: var(--color-warm-orange) !important;
    border-color: var(--color-warm-orange) !important;
    background-color: transparent !important;
}

.btn-secondary {
    color: var(--color-soothing-dark-blue) !important;
    border-color: var(--color-soothing-dark-blue) !important;
    background-color: transparent !important;
    font-size: 20px;
    height: 60px;
    border-radius: 15px;
    border-width: 3px;
    text-align: center;
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
    .btn-secondary {
        font-size: 18px;
        height: 50px;
        border-width: 2px;
    }
}

@media only screen and (max-width: 767px) {
    .btn-secondary {
        font-size: 16px;
        height: 40px;
        border-width: 2px;
    }
}

.logo-partner {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}

@media only screen and (max-width: 567px) {
    .logo-partner {
        width: 100%;
    }
}

.carousel-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    object-fit: cover;
}

.img-portrait {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    object-fit: cover;
}

.img-portrait-team {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: auto;
    object-fit: cover;
}

.btn-secondary:hover {
    color: var(--color-soothing-dark-blue) !important;
    border-color: var(--color-soothing-dark-blue) !important;
    background-color: #C0DE3A !important;
}

.btn-secondary:active {
    color: var(--color-soothing-dark-blue) !important;
    border-color: var(--color-soothing-dark-blue) !important;
    background-color: #A9D163 !important;
}

.btn-submit-form {
    color: var(--color-soothing-dark-blue) !important;
    border-color: var(--color-soothing-dark-blue) !important;
    background-color: transparent !important;
    font-size: 20px;
    height: 50px;
    border-radius: 15px;
    border-width: 3px;
    text-align: center;
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
    .btn-submit-form {
        font-size: 18px;
        height: 50px;
        border-width: 2px;
    }
}

@media only screen and (max-width: 767px) {
    .btn-submit-form {
        font-size: 16px;
        height: 40px;
        border-width: 2px;
    }
}

.btn-submit-form:hover {
    color: var(--color-smooth-white) !important;
    border-color: var(--color-soothing-dark-blue) !important;
    background-color: var(--color-soothing-dark-blue)!important;
}

.btn-submit-form:active {
    color: #C0DE3A !important;
    border-color: var(--color-soothing-dark-blue) !important;
    background-color: var(--color-soothing-dark-blue)!important;
}

.ant-tabs-nav .ant-tabs-tab {
    font-size: 24px !important;
    color: var(--color-smooth-white);
}

.ant-tabs-tab:hover {
    color: var(--color-hot-orange) !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
.ant-tabs-tab:focus, .ant-tabs-tab:active, .ant-tabs-tab-btn:focus, .ant-tabs-tab-btn:active,
.ant-tabs-tab-remove:active, .ant-tabs-tab-remove:focus{
    color: var(--color-navy-blue) !important;
}

.ant-tabs-ink-bar {
    position: absolute;
    background: var(--color-hot-orange);
    pointer-events: none;
}

.ant-tabs-ink-bar-animated {
    position: absolute;
    background: var(--color-navy-blue);
    pointer-events: none;
}

.ant-tabs-content-holder {
    padding: 1rem;
}

.ant-switch {
    background: var(--color-hot-orange);
    height: 32px;
}

.ant-switch-inner {
    color: var(--color-soothing-dark-blue);
    font-size: 16px;
}

.ant-switch-handle {
    top: 6px;
    left: 2px;
    width: 20px;
    height: 20px;
}

.ant-switch-handle:before {
    background-color: var(--color-smooth-white);
}

.ant-switch-checked .ant-switch-handle:before {
    background-color: var(--color-smooth-white);
}

.ant-switch-checked {
    background: var(--color-hot-orange);
}

.ant-switch.switch-toggle {
    background: var(--color-hot-orange);
}

.ant-switch-checked.switch-toggle {
    background: var(--color-navy-blue);
}

.ant-switch-checked .ant-switch-inner {
    color: var(--color-soothing-dark-blue);
}

.ant-switch-checked .ant-switch-handle {
    left:calc(100% - 22px)
}

.ant-switch.switch-on-light-bg {
    background: var(--color-soothing-dark-blue);
}

.switch-on-light-bg .ant-switch-inner {
    color: var(--color-smooth-white);
}

.ant-switch-checked.switch-on-light-bg .ant-switch-inner {
    color: var(--color-soothing-dark-blue);
}

.ant-switch-checked.switch-on-light-bg .ant-switch-handle:before {
    background-color: var(--color-soothing-dark-blue);
}

.ant-switch-checked.switch-on-light-bg {
    background: var(--color-navy-blue);
}

.ant-switch.switch-on-light-bg, .ant-switch-checked.switch-on-light-bg:focus {
    box-shadow:0 0 0 2px var(--color-soothing-dark-blue);
}

.ant-switch:focus, .ant-switch-checked:focus {
    box-shadow: none;
}

.ant-divider-horizontal.dashboard-divider-1 {
    color: var(--color-hot-orange);
    border-color: var(--color-hot-orange);
    font-size: 24px;
}

.ant-divider-horizontal.dashboard-divider-2 {
    color: var(--color-smooth-white);
    border-top-color: var(--color-smooth-white);
    font-size: 20px;
}

.ant-divider-horizontal.ant-divider-with-text:after.dashboard-divider-2,
.ant-divider-horizontal.ant-divider-with-text:before.dashboard-divider-2 {
    border-style: dashed;
    border-width: 3px;
    border-color: red;
}